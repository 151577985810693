import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { EyeToggler } from "../login";
import useErrorStore from "../../data/stores/errorstore";
import axios from "axios";
import { Button } from "../../components/button";

const Register = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let init = {
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      password: "",
      confirmPassword: "",
      userType: "",
    },
    [state, setState] = useState(init),
    navigate = useNavigate(),
    [show, setShow] = useState(null),
    [show1, setShow1] = useState(null),
    textChange = (e) => {
      let { name, value, type, title } = e.target;
      setState({ ...state, [name]: type === "radio" ? title : value });
    },
    { returnErrors } = useErrorStore(),
    [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    // setLoading(true);
    // const token = "zeno-auth-token";
    // localStorage.setItem("token", token);
    // login(token);
    // setLoading(false);
    e?.preventDefault();
    e.preventDefault();
    if (
      !state?.password ||
      !state?.email ||
      !state?.firstName ||
      !state?.lastName ||
      !state?.telephone
    )
      return toast.info("Please fill out all fields");
    if (state?.password !== state?.confirmPassword)
      return toast.error("Password do not match");

    if (
      !/\d/.test(state?.password) ||
      // eslint-disable-next-line no-useless-escape
      !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(state?.password) ||
      state?.password?.length < 8
    )
      return toast.error(
        `Password must be at least 8 characters long, contains at least 1 number, 1 uppercase, 1 lowercase letter and 1 special character`
      );
    setLoading(true);
    try {
      let res = await axios.post(`/api/v1/user`, {
				...state,
				nextScreen: `${window.location.origin}/activate`,
			});
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      navigate("/activate");
    } catch (err) {
      if (err?.response?.status === 429 || err?.response?.status === 405)
        toast.error(err?.response?.data ? err?.response?.data : err?.message);
      console.log({ err });
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        returnErrors(error);
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
    setLoading(false);
  };

  return (
		<>
			<div className="tw-py-10">
				<div className="fullHeight lg:tw-flex tw-hidden bg-white  ">
					<motion.div
						className="m-auto w-100 h-100 "
						style={{
							maxWidth: "550px",
						}}
						initial={{
							x: -250,
						}}
						animate={{
							x: 0,
						}}
						transition={{
							duration: 2,
							delay: 1,
							type: "tween",
						}}>
						<div
							style={{
								background: "rgba(255, 255, 255, 0.2)",
								border: "1px solid #0B2239",
								backdropFilter: "blur(7px)",
								borderRadius: "20px",
							}}
							className="border-bottom-4-color  p-3 py-md-5">
							<h2 className="text-center tw-text-4xl fw-bold  pb-1 text-[#0B2239]">
								Sign Up
							</h2>

							<p className="text-center   pb-4 text-[#0B2239]">
								Sign up to continue learning
							</p>

							<form onSubmit={handleSubmit}>
								<div className="tw-mb-2 tw-w-96 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Sign up as
									</p>
									<div className="tw-grid tw-grid-cols-2 tw-gap-4">
										<div className="tw-flex tw-text-[#013467] tw-justify-between tw-bg-[#F3F5F5] tw-p-3 tw-rounded">
											<p className="tw-mb-0">Individual</p>
											<input
												type="radio"
												value={state?.privilege}
												onChange={textChange}
												name="privilege"
												title="student"
											/>
										</div>
										<div className="tw-flex tw-text-[#013467] tw-justify-between tw-bg-[#F3F5F5] tw-p-3 tw-rounded">
											<p className="tw-mb-0">Company</p>
											<input
												type="radio"
												value={state?.privilege}
												onChange={textChange}
												name="privilege"
												title="company"
											/>
										</div>
										<div className="tw-flex tw-text-[#013467] tw-justify-between tw-bg-[#F3F5F5] tw-p-3 tw-rounded">
											<p className="tw-mb-0">Institution</p>
											<input
												type="radio"
												value={state?.privilege}
												onChange={textChange}
												name="privilege"
												title="institution"
											/>
										</div>
									</div>
								</div>
								<div className="mb-2 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										First Name
									</p>
									<input
										type="text"
										className="form-control tw-border-none tw-bg-[#F3F5F5] py-3"
										placeholder=""
										onChange={textChange}
										value={state?.firstName}
										name="firstName"
									/>
								</div>
								<div className="mb-2 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Last Name
									</p>
									<input
										type="text"
										className="form-control  tw-border-none tw-bg-[#F3F5F5] py-3"
										placeholder=""
										onChange={textChange}
										value={state?.lastName}
										name="lastName"
									/>
								</div>
								{["company", "institution"]?.includes(state?.privilege) && (
									<div className="mb-2 w-75 mx-auto">
										<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
											<span className="tw-capitalize">{state?.privilege}</span>{" "}
											Name
										</p>
										<input
											type="text"
											className="form-control  tw-border-none tw-bg-[#F3F5F5] py-3"
											placeholder=""
											onChange={textChange}
											value={state?.companyName}
											name="companyName"
										/>
									</div>
								)}
								<div className="mb-2 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Email Address
									</p>
									<input
										type="email"
										className="form-control  tw-border-none tw-bg-[#F3F5F5] py-3"
										placeholder=""
										onChange={textChange}
										value={state?.email}
										name="email"
									/>
								</div>
								<div className="mb-2 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Phone Address
									</p>{" "}
									<input
										type="tel"
										className="form-control  tw-border-none tw-bg-[#F3F5F5] py-3"
										placeholder=""
										onChange={textChange}
										value={state?.telephone}
										maxLength={11}
										name="telephone"
									/>
								</div>
								<div className="mb-2 w-75 mx-auto tw-relative">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Password
									</p>
									<input
										type={show ? "text" : "password"}
										className="form-control  tw-border-none tw-bg-[#F3F5F5] py-3"
										placeholder=""
										onChange={textChange}
										value={state?.password}
										name="password"
									/>
									<EyeToggler
										show={show}
										toggleShow={() => setShow(!show)}
										// color={"white"}
									/>
								</div>
								<div className="mb-2 w-75 mx-auto tw-relative">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Confirm Password
									</p>{" "}
									<input
										type={show1 ? "text" : "password"}
										className="form-control  tw-border-none tw-bg-[#F3F5F5] py-3"
										placeholder=""
										onChange={textChange}
										value={state?.confirmPassword}
										name="confirmPassword"
									/>
									<EyeToggler
										show={show1}
										toggleShow={() => setShow1(!show1)}
										// color={"white"}
									/>
								</div>

								<div className="mb-4 w-75 mx-auto">
									<Button
										type="submit"
										onClick={handleSubmit}
										loading={loading}
										children="Create Account"
										// eslint-disable-next-line react/style-prop-object
										css="tw-bg-main tw-w-96 tw-h-14 tw-rounded-3xl tw-mt-7 tw-text-white tw-text-base tw-font-bold satoshi tw-flex tw-justify-center tw-items-center"
									/>
								</div>
							</form>
							<p className="tw-text-base tw-font-medium tw-text-[#0B2239] Inter tw-text-center tw-pt-6">
								Have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className=" f tw-font-bold tw-text-sm tw-cursor-pointer tw-underline">
									Log in
								</span>
							</p>
						</div>
					</motion.div>
				</div>
				<div className="lg:tw-hidden tw-block tw-w-full tw-h-100 tw-bg-white fullHeight tw-pb-10">
					<motion.div
						className="m-auto w-100 h-100 "
						style={{
							maxWidth: "550px",
						}}
						initial={{
							x: -250,
						}}
						animate={{
							x: 0,
						}}
						transition={{
							duration: 2,
							delay: 1,
							type: "tween",
						}}>
						{/* <div className="text-center py-3 py-md-5 tw-mx-auto">
            <Logo position="tw-mx-auto" />
          </div> */}
						<div
							style={{
								background: "rgba(255, 255, 255, 0.2)",
								border: "1px solid #0B2239",
								backdropFilter: "blur(7px)",
								borderRadius: "20px",
							}}
							className="border-bottom-4-color  p-3 py-md-5">
							<h2 className="text-center text-uppercase fw-bold  pb-1 text-[#0B2239]">
								sign up
							</h2>

							<p className="text-center   pb-4 text-[#0B2239]">
								Sign up to continue learning
							</p>

							<form onSubmit={handleSubmit}>
								<div className="mb-4 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										First Name
									</p>
									<input
										type="text"
										className="form-control py-3"
										placeholder="First Name"
										onChange={textChange}
										value={state?.firstName}
										name="firstName"
									/>
								</div>
								<div className="mb-4 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Last Name
									</p>
									<input
										type="text"
										className="form-control py-3"
										placeholder="Last Name"
										onChange={textChange}
										value={state?.lastName}
										name="lastName"
									/>
								</div>
								{["company", "institution"]?.includes(state?.privilege) && (
									<div className="mb-2 w-75 mx-auto">
										<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
											<span className="tw-capitalize">{state?.privilege}</span>{" "}
											Name
										</p>
										<input
											type="text"
											className="form-control py-3"
											placeholder=""
											onChange={textChange}
											value={state?.companyName}
											name="companyName"
										/>
									</div>
								)}
								<div className="mb-4 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Email Address
									</p>
									<input
										type="email"
										className="form-control py-3"
										placeholder="Email"
										onChange={textChange}
										value={state?.email}
										name="email"
									/>
								</div>
								<div className="mb-4 w-75 mx-auto">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Phone Address
									</p>{" "}
									<input
										type="tel"
										className="form-control py-3"
										placeholder="Telephone"
										onChange={textChange}
										value={state?.telephone}
										maxLength={11}
										name="telephone"
									/>
								</div>
								<div className="mb-4 w-75 mx-auto tw-relative">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Password
									</p>
									<input
										type={show ? "text" : "password"}
										className="form-control py-3"
										placeholder="Password"
										onChange={textChange}
										value={state?.password}
										name="password"
									/>
									<EyeToggler
										show={show}
										toggleShow={() => setShow(!show)}
										// color={"white"}
									/>
								</div>
								<div className="mb-4 w-75 mx-auto tw-relative">
									<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
										Password
									</p>{" "}
									<input
										type={show1 ? "text" : "password"}
										className="form-control py-3"
										placeholder="Confirm Password"
										onChange={textChange}
										value={state?.confirmPassword}
										name="confirmPassword"
									/>
									<EyeToggler
										show={show1}
										toggleShow={() => setShow1(!show1)}
										// color={"white"}
									/>
								</div>

								<div className="mb-4 w-75 mx-auto">
									<Button
										type="submit"
										onClick={handleSubmit}
										loading={loading}
										children="Create Account"
										// eslint-disable-next-line react/style-prop-object
										css="form-control btn tw-mt-8 !tw-bg-[#0B2239] rounded-pill py-3 !tw-text-white hover:tw-bg-[#0B2239]"
									/>
								</div>
							</form>
							<p className="tw-text-base tw-font-medium tw-text-black Inter tw-text-center tw-pt-6">
								Have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className=" f tw-font-bold tw-text-sm tw-underline">
									Log in
								</span>
							</p>
						</div>
					</motion.div>
				</div>
			</div>
			{/* <PageFooter /> */}
		</>
	);
};

export default Register;
