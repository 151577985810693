import React, { useEffect, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import SelectCourseModal from "../modal/selectcourse";
import { useGroupsStore } from "../../data/stores/loggerstore";
import { apiCall } from "../../data/stores/authstore";
import moment from "moment";
import { LoadMore, MainPaginate } from "../button";

const AdminGroupsTable = () => {
	const [active, setActive] = useState(null);
	const [modal, setModal] = useState("");
	const navigate = useNavigate();
	const Headers = [
			"Group Name",
			"Number Of Members",
			"Instructors",
			"Date Created",
			"Action",
		],
		// currentItems = [
		//   {
		//     name: "Leader G",
		//     no_of_members: 42,
		//     instructors: 5,
		//     date: "07 May 1022",
		//   },
		// ],
		handleActive = i => {
			if (active === i) {
				setActive(null);
			} else {
				setActive(i);
			}
		},
		actions = [
			{
				name: "view group",
				action: state =>
					navigate(`/groups/${state?.title || active}`, { state }),
			},
			{
				name: "assign course",
				action: () => setModal("courses"),
			},
			{
				name: "share document",
			},
			{
				name: "edit group",
			},
			{
				name: "delete group",
			},
		];

	let [datum, setDatum] = useState({ docs: [] }),
		{ getLogger, mainSearch, isFound, data } = useGroupsStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall("get", `/api/v1/groups`, null, getLogger);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"get",
			`/api/v1/groups?limit=${data?.limit * data?.nextPage}`,
			null,
			getLogger
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className="">
				<table className="tw-min-w-full">
					<thead>
						<tr>
							{Headers?.filter(it => it)?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium tw-text-left satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.title}</td>
								<td className="tableText tw-capitalize">
									{item?.members?.length}
								</td>
								<td className="tableText">{item?.instructors?.length}</td>
								<td className="tableText">
									{moment(item?.createdAt).format("Do MMMM, YYYY")}
								</td>
								<td>
									<div className="tw-relative">
										<div className="tw-h-6 tw-ml-7 tw-w-3 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-[#87C3FF]">
											<SlOptionsVertical
												onClick={() => handleActive(i)}
												className="tw-cursor-pointer"
												color="#87C3FF"
											/>
										</div>
										{active === i && (
											<div className="tw-absolute tw-z-50 tw-rounded tw-bg-white tw-w-36 tw-p-2 tw-top-8 tw-left-[-5rem]">
												{actions?.map(act => (
													<button
														onClick={() =>
															act?.action ? act?.action(item) : {}
														}
														style={{
															borderBottom: "0.5px solid #00000033",
														}}
														className="tw-h-8 tw-w-full tw-text-left tw-capitalize tw-text-sm tw-font-normal tw-text-secondary">
														{act?.name}
													</button>
												))}
											</div>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
					<LoadMore
						next={data?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
				{modal === "courses" && (
					<SelectCourseModal handleClose={() => setModal("")} />
				)}
			</div>
		</div>
	);
};

export const MembersTable = ({ datum }) => {
	const [active, setActive] = useState(null),
		navigate = useNavigate();
	const headers = [
			"name",
			"department",
			"date added",
			"progress",
			"status",
			"action",
		],
		// data = [
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 		progress: "100%",
		// 		status: "completed",
		// 	},
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 		progress: "100%",
		// 		status: "completed",
		// 	},
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 		progress: "100%",
		// 		status: "completed",
		// 	},
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 		progress: "100%",
		// 		status: "progress",
		// 	},
		// ],
		handleActive = i => {
			if (active === i) {
				setActive(null);
			} else {
				setActive(i);
			}
		},
		actions = [
			{
				name: "view member",
				action: state =>
					navigate(`/staffs/${state?.firstName || active}`, { state }),
			},
			// {
			// 	name: "add member",
			// },
		];

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className="">
				<table className="tw-min-w-full">
					<thead>
						<tr>
							{headers
								?.filter(it => it)
								?.map(item => (
									<th className="tw-text-secondary tw-p-3 tw-font-medium tw-text-left satoshi tw-text-sm tw-capitalize">
										{item}
									</th>
								))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">
									{item?.firstName} {item?.lastName}
								</td>
								<td className="tableText tw-capitalize">{item?.department}</td>
								<td className="tableText">
									{moment(item?.createdAt).format("Do MMMM, YYYY")}
								</td>
								<td className="tableText">{item?.progress}</td>
								<td
									style={{
										color: item?.status === "completed" ? "#0B6C25" : "#2100A5",
									}}
									className="tableText tw-capitalize">
									{item?.status}
								</td>
								<td>
									<div className="tw-relative">
										<div className="tw-h-6 tw-ml-7 tw-w-3 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-[#87C3FF]">
											<SlOptionsVertical
												onClick={() => handleActive(i)}
												className="tw-cursor-pointer"
												color="#87C3FF"
											/>
										</div>
										{active === i && (
											<div className="tw-absolute tw-z-50 tw-rounded tw-bg-white tw-w-36 tw-p-2 tw-top-8 tw-left-[-5rem]">
												{actions?.map(act => (
													<button
														onClick={() =>
															act?.action ? act?.action(item) : {}
														}
														style={{
															borderBottom: "0.5px solid #00000033",
														}}
														className="tw-h-8 tw-w-full tw-text-left tw-capitalize tw-text-sm tw-font-normal tw-text-secondary">
														{act?.name}
													</button>
												))}
											</div>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

export const InstructorsTable = ({ datum }) => {
	const [active, setActive] = useState(null),
		navigate = useNavigate();
	const headers = ["name", "department", "date added", "action"],
		// data = [
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 	},
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 	},
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 	},
		// 	{
		// 		name: "Ajayi Henry",
		// 		department: "Safety Unit",
		// 		date: "02 May, 2022",
		// 	},
		// ],
		handleActive = i => {
			if (active === i) {
				setActive(null);
			} else {
				setActive(i);
			}
		},
		actions = [
			{
				name: "view member",
				action: state =>
					navigate(`/instructors/${state?.firstName || active}`, { state }),
			},
			// {
			// 	name: "add member",
			// },
		];

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className="">
				<table className="tw-min-w-full">
					<thead>
						<tr>
							{headers
								?.filter(it => it)
								?.map(item => (
									<th className="tw-text-secondary tw-p-3 tw-font-medium tw-text-left satoshi tw-text-sm tw-capitalize">
										{item}
									</th>
								))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">
									{item?.firstName} {item?.lastName}
								</td>
								<td className="tableText tw-capitalize">{item?.department}</td>
								<td className="tableText">
									{moment(item?.createdAt).format("Do MMMM, YYYY")}
								</td>
								<td>
									<div className="tw-relative">
										<div className="tw-h-6 tw-ml-7 tw-w-3 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-[#87C3FF]">
											<SlOptionsVertical
												onClick={() => handleActive(i)}
												className="tw-cursor-pointer"
												color="#87C3FF"
											/>
										</div>
										{active === i && (
											<div className="tw-absolute tw-z-50 tw-rounded tw-bg-white tw-w-36 tw-p-2 tw-top-8 tw-left-[-5rem]">
												{actions?.map(act => (
													<button
														onClick={() =>
															act?.action ? act?.action(item) : {}
														}
														style={{
															borderBottom: "0.5px solid #00000033",
														}}
														className="tw-h-8 tw-w-full tw-text-left tw-capitalize tw-text-sm tw-font-normal tw-text-secondary">
														{act?.name}
													</button>
												))}
											</div>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

export default AdminGroupsTable;
