/* eslint-disable react/prop-types */
import { createContext, useState } from "react";
import DashboardIcon from "../assets/dashboard.svg";
import CoursesIcon from "../assets/courses.svg";
import CertificateIcon from "../assets/certificates.svg";
import Certactive from "../assets/certactive.svg";
import DashboardActive from "../assets/dashboardactive.svg";
import CoursesActive from "../assets/coursesactive.svg";
import StudentIcon from "../assets/students.svg";
import ReportIcon from "../assets/report.svg";
import useAuthStore from "./stores/authstore";
import Payment from "../assets/payicon.svg";
import GroupIcon from "../assets/groups.svg";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
  const [nav, setNav] = useState(false);
  let handleCapitalize = (word) => {
    let splitter = word.trim().split(" ");
    let firstCap = splitter[0].split("");
    let remaining = splitter.slice(1, splitter.length).join(" ");

    let firstCapOne = firstCap[0].toUpperCase();
    let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

    let joinFirst = `${firstCapOne}${firstCapTwo}`;

    return `${joinFirst} ${remaining}`;
  };

  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let nairaSign = <span className="fontInherit">&#8358;</span>;
  let nairaSignNeutral = "₦";

  const toggleNav = () => {
    setNav(!nav);
  };

  let { userPage, isAuth, user } = useAuthStore();

  let sidebarList = [
		{
			name: "Dashboard",
			icon: DashboardIcon,
			active: DashboardActive,
			url: "/dashboard",
			show: true,
		},
		{
			name: "Courses",
			icon: CoursesIcon,
			active: CoursesActive,
			url: "/courses",
			show: true,
		},
		{
			name: "Sessions",
			icon: StudentIcon,
			// active: Certactive,
			url: "/sessions",
			show:
				isAuth &&
				((userPage === "student" &&
					user?.privilege === "student" &&
					user?.organisation !== user?._id) ||
					userPage === "institution"),
		},
		{
			name: userPage === "institution" ? "Students" : "Staffs",
			icon: StudentIcon,
			url: "/staffs",
			show: isAuth && ["company", "institution"]?.includes(userPage),
		},
		{
			name: "Instructors",
			icon: StudentIcon,
			url: "/instructors",
			show: isAuth && userPage === "institution",
		},
		{
			name: "Admins",
			icon: StudentIcon,
			url: "/admins",
			show:
				isAuth &&
				user?.privilege === "institution" &&
				userPage === "institution",
		},
		{
			name: "Certificates",
			icon: CertificateIcon,
			active: Certactive,
			url: "/certificates",
			show: isAuth && userPage === "student",
		},
		{
			name: "Payments",
			icon: Payment,
			active: Payment,
			url: "/payments",
			show: isAuth && userPage === "company",
		},
		{
			name: "Report",
			icon: ReportIcon,
			url: "/reports",
			show: isAuth && userPage === "institution",
		},
		{
			name: "Groups",
			icon: GroupIcon,
			url: "/groups",
			show:
				isAuth &&
				((userPage === "student" &&
					user?.privilege === "student" &&
					user?.organisation !== user?._id) ||
					userPage === "institution" ||
					userPage === "instructor"),
		},
	];

  const state = {
    handleCapitalize,

    numberWithCommas,

    sidebarList,
    auth: isAuth,
    nav,
    toggleNav,
    nairaSign,
    nairaSignNeutral,
  };

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
