import React from "react";
import { ModalContainer } from "../modals/creategroupmodal";
import { SearchInput } from "../inputs";

const SelectCourseModal = ({ handleClose }) => {
  const courses = [
    "10 ways of Living Healthy",
    "10 ways of Living Healthy",
    "10 ways of Living Healthy",
    "10 ways of Living Healthy",
    "10 ways of Living Healthy",
    "10 ways of Living Healthy",
  ];
  return (
    <div>
      <ModalContainer handleClose={handleClose}>
        <div className="tw-p-3">
          <div className="tw-flex tw-justify-between tw-items-center">
            <strong className="tw-text-secondary tw-font-bold tw-text-xl satoshi">
              Select Course
            </strong>
            <SearchInput />
          </div>
          <div
            style={{
              border: "1px solid #0134674D",
            }}
            className="tw-p-4 tw-rounded-xl tw-mt-6"
          >
            <h4 className="tw-text-base tw-font-medium tw-text-secondary">
              Courses
            </h4>
            <div className="tw-mt-4">
              {courses?.map((course) => (
                <div
                  style={{
                    borderBottom: "0.5px solid #01346780",
                  }}
                  className="tw-h-12 tw-flex tw-px-2 tw-gap-4 tw-items-center"
                >
                  <input type="checkbox" name="" className="" id="" />
                  <small className="tw-text-sm tw-font-normal tw-text-main">
                    {course}
                  </small>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default SelectCourseModal;
