import React, { useState, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Bulb from "../../assets/bulb.svg";
import ProfileImg from "../../assets/profileimg.svg";
import Complete from "../../assets/completed.svg";
import Ongoing from "../../assets/ongoing.svg";
import LockedOut from "../../assets/locked-out.svg";
import Earned from "../../assets/earned.svg";
import AllcoursesImg from "../../assets/allcourses.png";
import Play from "../../assets/play.svg";
import { useNavigate } from "react-router";
import Duration from "../../assets/duration.svg";
import Videos from "../../assets/videos.svg";
import useCourseStore from "../../data/stores/coursestore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import { MainPaginate } from "../../components/button";
import { CourseDashTable } from "../../components/tables/userstable";

const Arr = [
    {
      name: "completed courses",
      value: <CourseDashTable type={"completed"} />,
      icon: Complete,
    },
    {
      name: "ongoing courses",
      value: <CourseDashTable />,
      icon: Ongoing,
    },
    {
      name: "locked out courses",
      value: <CourseDashTable type={"lock-out"} />,
      icon: LockedOut,
    },
    {
      name: "certificates earned",
      value: <CourseDashTable type={"certificate"} />,
      icon: Earned,
    },
  ],
  Arr3 = [
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: AllcoursesImg,
      title: "leadership",
      videos: "4",
      duration: "23",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
  ];

const Dashboard = () => {
  let { user } = useAuthStore();
  return (
    <div>
      <MainContainer>
        <MainHeader
          text={`Welcome ${user?.privilege || ""} ${user?.firstName || ""} ${
            user?.lastName || ""
          }`}
          small={"Dashboard"}
        />
        <>
          <div className="tw-flex tw-mt-8 tw-flex-col lg:tw-flex-row tw-gap-8">
            <div className="lg:tw-w-[70%] banner tw-relative tw-rounded-2xl tw-bg-main lg:tw-h-72">
              <div className="tw-py-5 tw-px-3">
                <h5 className="tw-text-sm tw-text-white tw-font-medium tw-uppercase">
                  online course
                </h5>
                <h1 className="tw-pt-2 lg:tw-text-5xl tw-text-2xl tw-font-extrabold tw-text-white">
                  Sharpen Your Skills With
                  <br />
                  Professional Online <br /> Courses
                </h1>
              </div>
              <img
                src={Bulb}
                alt=""
                className="tw-absolute tw-hidden lg:tw-block tw-bottom-0 tw-right-0"
              />
            </div>
            <div className="lg:tw-w-[30%] lg:tw-h-72 tw-relative tw-bg-white tw-rounded-xl">
              <img
                src={user?.image?.url || ProfileImg}
                alt=""
                className="tw-mx-auto tw-h-44 lg:tw-w-full"
              />
              <div className="tw-absolute tw-bottom-3 tw-flex tw-justify-center tw-w-full">
                <div>
                  <h3 className="lg:tw-text-2xl tw-text-base tw-font-extrabold tw-text-main tw-text-center">
                    {user?.firstName} {user?.lastName}
                  </h3>
                  <h6 className="tw-text-sm lg:tw-text-base tw-text-center tw-font-normal tw-text-main">
                    You have four (4) notifications
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-mt-8 tw-grid lg:tw-grid-cols-4 tw-gap-5">
            {Arr?.map((item, i) => (
              <StatCard
                key={i}
                icon={item?.icon}
                name={item?.name}
                value={item?.value}
              />
            ))}
          </div>
          <OngoingCouses title={"Your Course"} />
          <EnrollCouses />
        </>
      </MainContainer>
    </div>
  );
};

const StatCard = ({ icon, name, value }) => {
  return (
    <>
      <div className="tw-h-28 tw-bg-white tw-rounded-xl tw-gap-5 tw-flex tw-items-center tw-px-3">
        <img src={icon} alt="" className="" />
        <div>
          <h2
            style={{
              paddingBottom: "0px !important",
            }}
            className="tw-text-2xl tw-font-bold tw-text-[#1c1c1c]"
          >
            {value}
          </h2>
          <small className="tw-text-sm tw-font-medium tw-capitalize tw-text-black">
            {name}
          </small>
        </div>
      </div>
    </>
  );
};

export const CoursesCard = ({ img, title, description, progress, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };
  return (
    <>
      <div
        onMouseOver={handleHover}
        onMouseOut={handleMouseOut}
        onClick={onClick}
        style={{
          border: "0.5px solid rgba(1, 52, 103, 0.30)",
        }}
        className="tw-rounded-lg tw-relative tw-cursor-pointer tw-bg-white"
      >
        <div className="tw-relative">
          <img src={img} alt="" className="tw-rounded-t-lg tw-w-full" />
          {hovered && (
            <div className="tw-absolute tw-w-full tw-left-0 tw-top-0 tw-h-full tw-bg-black tw-bg-opacity-30 tw-flex tw-items-center tw-justify-center tw-cursor-pointer">
              <div className="tw-h-10 tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-bg-white">
                <img src={Play} alt="" className="" />
              </div>
            </div>
          )}
        </div>
        <div className="tw-py-2 tw-relative tw-px-4">
          <h6 className="tw-text-base tw-capitalize tw-text-secondary tw-font-bold">
            {title}
          </h6>
          <p className="tw-text-xs tw-font-normal tw-text-main">
            {description}
          </p>
          <div className="tw-mt-2 tw-w-full tw-bg-[#CFCFCF] tw-h-2 tw-rounded">
            <div
              className={`tw-h-full tw-w-[${progress}%] tw-bg-myblue tw-rounded`}
            ></div>
          </div>
          <small className="tw-text-xs tw-text-main">
            {progress}% Complete
          </small>
        </div>
      </div>
    </>
  );
};

export const CoursesCard2 = ({
  img,
  title,
  description,
  videos,
  duration,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };
  return (
    <>
      <div
        onMouseOver={handleHover}
        onMouseOut={handleMouseOut}
        onClick={onClick}
        style={{
          border: "0.5px solid rgba(1, 52, 103, 0.30)",
        }}
        className="tw-rounded-lg tw-relative tw-cursor-pointer tw-bg-white"
      >
        <div className="tw-relative">
          <img src={img} alt="" className="tw-rounded-t-lg tw-w-full" />
          {hovered && (
            <div className="tw-absolute tw-w-full tw-left-0 tw-top-0 tw-h-full tw-bg-black tw-bg-opacity-30 tw-flex tw-items-center tw-justify-center tw-cursor-pointer">
              <div className="tw-h-10 tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-bg-white">
                <img src={Play} alt="" className="" />
              </div>
            </div>
          )}
        </div>
        <div className="tw-py-2 tw-relative tw-px-4">
          <h6 className="tw-text-base tw-capitalize tw-text-secondary tw-font-bold">
            {title}
          </h6>
          <p className="tw-text-xs tw-font-normal tw-text-main">
            {description}
          </p>

          <div className="tw-mt-4 tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-2">
              <img src={Videos} alt="" className="" />
              <small className="tw-text-sm tw-font-light satoshi tw-text-main">
                {videos} videos
              </small>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2">
              <img src={Duration} alt="" className="" />
              <small className="tw-text-sm tw-font-light satoshi tw-text-main">
                {duration} hours
              </small>
            </div>
          </div>
          <div className="tw-mt-2">
            <small className="tw-text-sm tw-font-medium tw-underline tw-text-[#0269D0] satoshi">
              Learn More
            </small>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;

export const EnrollCouses = () => {
  const navigate = useNavigate();

  let [datum, setDatum] = useState(null),
    { isFound, mainSearch, enroll, getCourseToEnroll, resetCourseSearch } =
      useCourseStore();
  useEffect(() => {
    if (isFound) setDatum(mainSearch);
    else setDatum(enroll);
  }, [enroll, isFound, mainSearch]);

  useEffect(() => {
    apiCall("get", "/api/v1/course?enroll=enroll", null, getCourseToEnroll);
    resetCourseSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [range] = useState(10);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  // if (!datum && !data) return <PageLoader />;
  if (!datum) return;

  const currentItems = datum?.docs?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(datum?.docs?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % datum?.docs?.length;
    setItemOffset(newOffset);
  };
  return (
    <div className="tw-mt-8 tw-bg-white tw-rounded-xl tw-p-6">
      <h2 className="tw-text-secondary tw-font-extrabold tw-text-xl">
        All Courses
      </h2>
      <div className="tw-grid lg:tw-grid-cols-4 tw-gap-6 tw-mt-6">
        {currentItems?.map((item, i) => (
          <CoursesCard2
            key={i}
            img={
              item?.image?.url ||
              item?.module?.[0]?.image?.url ||
              item?.module?.[0]?.section?.[0]?.image?.url ||
              Arr3?.[i % (Arr3?.length - 1)]?.img
            }
            text={"Learn More"}
            description={item?.description}
            title={item?.title}
            videos={item?.module?.reduce?.(
              (ac, i) => (ac += i?.section?.length),
              0
            )}
            duration={item?.duration ? item?.duration % 60 : 0}
            onClick={() => navigate(`/courses/details`, { state: item })}
          />
        ))}
      </div>
      {pageCount > 1 && (
        <MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
      )}
    </div>
  );
};

export const OngoingCouses = ({ title, subtitle, noApi, type }) => {
  const navigate = useNavigate();

  let [datum, setDatum] = useState(null),
    { isFound, mainSearch, data, getCourse, resetCourseSearch } =
      useCourseStore(),
    { user } = useAuthStore();

  useEffect(() => {
    let dd = [],
      dd2 = {};
    if (isFound) {
      dd = mainSearch?.docs;
      dd2 = mainSearch;
    } else {
      dd = data?.docs;
      dd2 = data;
    }
    if (type === "completed")
      dd = dd?.filter((it) => it?.progress?.status === "completed");
    if (type === "lock-out")
      dd = dd?.filter((it) => it?.progress?.status === "lock-out");
    setDatum({ ...dd2, docs: dd });
  }, [data, isFound, mainSearch, type]);

  useEffect(() => {
    if (!noApi) {
      apiCall(
        "get",
        `/api/v1/course${
          user?.organisation !== user?._id && user?.privilege === "student"
            ? `?institution=${user?.organisation}`
            : ""
        }`,
        null,
        getCourse
      );
      resetCourseSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noApi]);

  let [range] = useState(10);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  // if (!datum && !data) return <PageLoader />;
  if (!datum) return;

  const currentItems = datum?.docs?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(datum?.docs?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % datum?.docs?.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="tw-mt-8 tw-bg-white tw-rounded-xl tw-p-6">
      <h2 className="tw-text-secondary tw-font-extrabold tw-text-xl">
        {title}
        {subtitle && (
          <span className="tw-text-sm tw-font-normal">({subtitle})</span>
        )}
      </h2>
      <div className="tw-grid lg:tw-grid-cols-4 tw-gap-6 tw-mt-6">
        {currentItems?.map((item, i) => (
          <CoursesCard
            key={i}
            onClick={() => navigate(`/courses/${item?.title}`, { state: item })}
            img={
              item?.image?.url ||
              item?.module?.[0]?.image?.url ||
              item?.module?.[0]?.section?.[0]?.image?.url ||
              Arr3?.[i % (Arr3?.length - 1)]?.img
            }
            text={"View"}
            description={item?.description}
            title={item?.title}
            videos={item?.module?.reduce?.(
              (ac, i) => (ac += i?.section?.length),
              0
            )}
            duration={item?.duration ? item?.duration % 60 : 0}
            progress={
              ((item?.progress?.section?.length || 0) /
                item?.module?.reduce?.(
                  (ac, i) => (ac += i?.section?.length),
                  0
                )) *
                100 || 0
            }
          />
        ))}
      </div>
      {pageCount > 1 && (
        <MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
      )}
    </div>
  );
};
