import React, { useEffect, useState } from "react";
import { LoadMore, MainPaginate, Tablebtn } from "../button";
import { useNavigate } from "react-router-dom";
import { useGroupsStore } from "../../data/stores/loggerstore";
import { apiCall } from "../../data/stores/authstore";
import moment from "moment";

const GroupsTable = () => {
	const navigate = useNavigate();
	const Headers = [
		"group name",
		"No. Of Members",
		"assigned courses",
		"date added",
		"action",
	];
	// data = [
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	// ];

	let [datum, setDatum] = useState({ docs: [] }),
		{ getLogger, mainSearch, isFound, data } = useGroupsStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall("get", `/api/v1/groups`, null, getLogger);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"get",
			`/api/v1/groups?limit=${data?.limit * data?.nextPage}`,
			null,
			getLogger
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.title}</td>
								<td className="tableText">{item?.members?.length}</td>
								<td className="tableText">{item?.courses?.length}</td>
								<td className="tableText">
									{moment(item?.createdAt).format("Do MMMM, YYYY")}
								</td>
								<td>
									<div className="">
										<Tablebtn
											onClick={() =>
												navigate(`/groups/${item?.title || i}`, { state: item })
											}
											text={"View"}
										/>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
					<LoadMore
						next={data?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

export const AssignedTable = () => {
	const Headers = [
			"name",
			"Assign by",
			"date assigned",
			"document shared",
			"action",
		],
		data = [
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
		];
	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.name}</td>
								<td className="tableText">{item?.assigned_by}</td>
								<td className="tableText">{item?.date_registered}</td>
								<td className="tableText tw-text-[#0B2239] tw-font-medium tw-underline tw-cursor-pointer">
									View Document
								</td>
								<td>
									<div className="">
										<Tablebtn text={"Start"} />
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export const OngoingTable = () => {
	const Headers = [
			"name",
			"Assign by",
			"date assigned",
			"document shared",
			"action",
		],
		data = [
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
		];
	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.name}</td>
								<td className="tableText">{item?.assigned_by}</td>
								<td className="tableText">{item?.date_registered}</td>
								<td className="tableText tw-text-[#0B2239] tw-font-medium tw-underline tw-cursor-pointer">
									View Document
								</td>
								<td>
									<div className="">
										<Tablebtn text={"Resume"} />
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export const CompletedTable = () => {
	const Headers = ["name", "Assign by", "date assigned", "document shared"],
		data = [
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
		];
	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.name}</td>
								<td className="tableText">{item?.assigned_by}</td>
								<td className="tableText">{item?.date_registered}</td>
								<td className="tableText tw-text-[#0B2239] tw-font-medium tw-underline tw-cursor-pointer">
									View Document
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default GroupsTable;
