import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { Button } from "../../components/button";
import {
  InstructorsTable,
  MembersTable,
} from "../../components/tables/admingrouptable";
import AddMemberModal from "../../components/modals/addmembermodal";
import { useLocation } from "react-router-dom";
import SelectCourseModal from "../../components/modal/selectcourse";
import { ChatSection } from "../../pages/groups/[id]";

const GroupDetails = () => {
	const [tab, setTab] = useState("members"),
		[modal, setModal] = useState(""),
		tabs = ["members", "instructors", "courses"],
		{ state } = useLocation();
	return (
		<div>
			<MainContainer>
				<MainHeader text={"All Groups"} small={"334 Groups"} />
				<div className="tw-bg-white tw-mt-8 tw-rounded-xl noScroll tw-p-6">
					<h5 className="tw-text-xl tw-font-bold tw-text-secondary">
						{state?.title}
					</h5>
					<div className="tw-mt-6 tw-flex tw-justify-between tw-items-center">
						<div className="tw-flex tw-gap-6">
							{tabs?.map(t => (
								<button
									onClick={() => setTab(t)}
									className={`tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize ${
										tab === t ? "tw-bg-[#D9ECFF]" : "tw-bg-transparent"
									}`}>
									{t}
								</button>
							))}
						</div>
						<div className="tw-flex tw-gap-5 tw-items-center">
							<strong className="tw-text-base tw-font-medium tw-text-[#E34033] satoshi tw-border-b tw-border-b-[#E34033]">
								Delete Group
							</strong>
							{tab === "members" ? (
								<button
									onClick={() => setModal("add-member")}
									className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]">
									Add Member
								</button>
							) : tab === "courses" ? (
								<button
									onClick={() => setModal("add-courses")}
									className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]">
									Assign Courses
								</button>
							) : (
								<button
									onClick={() => setModal("add-instructor")}
									className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]">
									Add Instructor
								</button>
							)}
							<Button onClick={() => setTab("chat")}>Chat</Button>
							<Button>Export</Button>
						</div>
					</div>
					<div className="tw-mt-6">
						{tab === "members" && <MembersTable datum={state?.members} />}
						{tab === "courses" && <></>}
						{tab === "chat" && <ChatSection />}
						{tab === "instructors" && (
							<InstructorsTable datum={state?.instructors} />
						)}
					</div>
				</div>
			</MainContainer>
			{["add-member", "add-instructor"]?.includes(modal) && (
				<AddMemberModal
					handleClose={() => setModal("")}
					title={modal === "add-member" ? "Member" : "Instructor"}
					name={modal === "add-member" ? "members" : "instructors"}
				/>
			)}
			{modal === "add-courses" && (
				<SelectCourseModal handleClose={() => setModal("")} />
			)}
			{/* {modal === "add-instructor" && (
				<AddInstructorModal handleClose={() => setModal("")} />
			)} */}
		</div>
	);
};

export default GroupDetails;
