import React, { useState } from "react";
import { ModalContainer } from "./creategroupmodal";
import { TextInput } from "../inputs";
import { Button } from "../button";
import { useLocation } from "react-router-dom";
import { useGroupsStore } from "../../data/stores/loggerstore";
import useErrorStore from "../../data/stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/stores/authstore";

const AddMemberModal = ({ handleClose, name, title }) => {
	let [state, setState] = useState({}),
		items = useLocation()?.state,
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ updateLogger } = useGroupsStore(),
		{ returnErrors } = useErrorStore(),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.[name]) return toast.info(`${title} is required`);
			let newState = state;
			if (state?.members)
				newState.members = state?.members
					?.trim()
					?.split(",")
					?.map(it => it?.trim());
			if (state?.instructors)
				newState.instructors = state?.instructors
					?.trim()
					?.split(",")
					?.map(it => it?.trim());

			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"put",
				`/api/v1/groups?group=${items?._id}`,
				newState
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				updateLogger(response);
				setState(null);
				handleClose();
				return;
			}
			setLoading(false);
		};

	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-bg-white tw-p-5">
					<h1 className="tw-text-xl tw-font-medium satoshi tw-text-secondary">
						Add {title || "Member"}
					</h1>
					<form action="" className="tw-mt-6">
						{name === "title" && (
							<TextInput
								label={"Name of Group"}
								onChange={textChange}
								value={state?.title}
								name="title"
							/>
						)}
						{name === "instructors" && (
							<TextInput
								label={"Add Supervisor"}
								onChange={textChange}
								value={state?.instructors}
								name="instructors"
							/>
						)}
						{name === "members" && (
							<TextInput
								label={"Add Member"}
								onChange={textChange}
								value={state?.members}
								name="members"
							/>
						)}
						<div className="tw-mt-8">
							<Button onClick={handleSubmit} loading={loading} type="button">
								Add {title || "Member"}
							</Button>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const AddInstructorModal = ({ handleClose }) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-bg-white tw-p-5">
					<h1 className="tw-text-xl tw-font-medium satoshi tw-text-secondary">
						Add Instructor
					</h1>
					<form action="" className="tw-mt-6">
						<TextInput
							label={"Name of Instructor"}
							placeholder={"Search name of member"}
						/>
						<div className="tw-mt-8">
							<Button>Add Instructor</Button>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};
export default AddMemberModal;
