import React, { useState } from "react";
import { TextInput } from "../inputs";
import { MainBtn } from "../button";
import { useGroupsStore } from "../../data/stores/loggerstore";
import useErrorStore from "../../data/stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/stores/authstore";

const CreateGroupModal = ({ handleClose }) => {
	let [state, setState] = useState({}),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ addLogger } = useGroupsStore(),
		{ returnErrors } = useErrorStore(),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.title) return toast.info("Last name is required");
			let newState = state;
			if (state?.members)
				newState.members = state?.members
					?.trim()
					?.split(",")
					?.map(it => it?.trim());
			if (state?.instructors)
				newState.instructors = state?.instructors
					?.trim()
					?.split(",")
					?.map(it => it?.trim());

			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/groups`,
				newState
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				addLogger(response);
				setState(null);
				handleClose();
				return;
			}
			setLoading(false);
		};
	return (
		<div>
			<div
				onClick={e => e.target === e.currentTarget && handleClose()}
				className="tw-fixed tw-z-50 tw-inset-0 tw-bg-black tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
				<div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-rounded-xl tw-h-1/2 noScroll tw-overflow-y-auto">
					<div className="tw-bg-white tw-rounded-xl tw-w-full tw-p-5">
						<h1 className="tw-text-xl tw-font-medium satoshi tw-text-secondary">
							Create Group
						</h1>
						<form
							action=""
							onSubmit={e => e?.preventDefault()}
							className="tw-mt-5">
							<div className="tw-space-y-4">
								<TextInput
									label={"Name of Group"}
									onChange={textChange}
									value={state?.title}
									name="title"
								/>
								<TextInput
									label={"Add Supervisor"}
									onChange={textChange}
									value={state?.instructors}
									name="instructors"
								/>
								<TextInput
									label={"Add Member"}
									onChange={textChange}
									value={state?.members}
									name="members"
								/>
							</div>
							<div className="tw-mt-6">
								<MainBtn
									text={"Create Group"}
									onClick={handleSubmit}
									loading={loading}
									type="button"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export const ModalContainer = ({ handleClose, children }) => {
	return (
		<div
			onClick={e => e.target === e.currentTarget && handleClose()}
			className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			<div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-max-h-96 tw-rounded-xl  noScroll tw-overflow-y-auto">
				{children}
			</div>
		</div>
	);
};

export default CreateGroupModal;
