import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import {
  AssignedTable,
  CompletedTable,
  OngoingTable,
} from "../../components/tables/groupstable";
import Avatar from "../../assets/Category tab.svg";
import { useLocation } from "react-router-dom";

export const MainGroupsDetail = () => {
	const [tab, setTab] = useState("assigned"),
		tabs = ["assigned", "ongoing", "completed", "chat"],
		{ state } = useLocation();

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Courses"} />
				<div className="tw-flex tw-flex-col tw-mt-8 lg:tw-flex-row tw-gap-6">
					<div className="lg:tw-w-[70%] tw-p-5 tw-bg-white tw-rounded-xl">
						<h3 className="tw-text-xl tw-font-bold tw-text-secondary satoshi">
							All Groups
						</h3>
						<div className="tw-mt-5 tw-flex tw-gap-5">
							{tabs?.map(tb => (
								<button
									onClick={() => setTab(tb)}
									className={`tw-px-5 tw-h-10 tw-rounded-full satoshi tw-text-base tw-font-medium tw-capitalize ${
										tab === tb
											? "tw-bg-[#0269D0] tw-text-white"
											: "tw-bg-[#0269D01A] tw-text-[#0269D0]"
									}`}>
									{tb}
								</button>
							))}
						</div>
						<div className="tw-mt-6">
							{tab === "assigned" && <AssignedTable />}
							{tab === "ongoing" && <OngoingTable />}
							{tab === "completed" && <CompletedTable />}
							{tab === "chat" && <ChatSection />}
						</div>
					</div>
					<div className="lg:tw-w-[30%] tw-space-y-12">
						<div
							style={{
								border: "0.5px solid #01346780",
							}}
							className="tw-bg-white tw-rounded-xl tw-p-6">
							<h4 className="tw-text-base tw-font-medium tw-text-secondary">
								Instructors
							</h4>
							<div className="tw-mt-4 tw-w-full">
								{state?.instructors?.map((ins, i) => (
									<div
										key={i}
										style={{
											borderBottom: "0.5px solid #0134674D",
										}}
										className="tw-h-12 tw-w-full tw-flex tw-justify-between tw-px-3 tw-items-center tw-cursor-pointer">
										<span className="tw-text-sm tw-font-normal tw-text-secondary">
											{ins?.firstName} {ins?.lastName}
										</span>
										<span className="tw-text-sm tw-font-normal tw-capitalize tw-text-[#0269D0]">
											{ins?.status}
										</span>
									</div>
								))}
							</div>
						</div>
						<div
							style={{
								border: "0.5px solid #01346780",
							}}
							className="tw-bg-white tw-rounded-xl tw-p-6">
							<h4 className="tw-text-base tw-font-medium tw-text-secondary">
								Group Members
							</h4>
							<div className="tw-mt-4 tw-w-full">
								{state?.members?.map((ins, i) => (
									<div
										key={i}
										// onClick={() => setChat(true)}
										style={{
											borderBottom: "0.5px solid #0134674D",
										}}
										className="tw-h-12 tw-cursor-pointer tw-w-full tw-flex tw-justify-between tw-px-3 tw-items-center">
										<span className="tw-text-sm tw-font-normal tw-text-secondary">
											{ins?.firstName} {ins?.lastName}
										</span>
										<span className="tw-text-sm tw-font-normal tw-capitalize tw-text-[#0269D0]">
											{ins?.status}
										</span>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export const ChatSection = () => {
	return (
		<div className="lg:tw-w-[70%] tw-p-5 tw-bg-white tw-rounded-xl tw-min-h-full tw-flex tw-flex-col">
			<h2 className="tw-text-xl tw-font-bold tw-text-secondary">
				Course Chat- English Language
			</h2>
			<div className="tw-mt-5 tw-space-y-5">
				<div className="tw-float-start tw-w-3/4 tw-flex tw-gap-4">
					<img src={Avatar} alt="" className="" />
					<div
						style={{
							border: "1px solid #EBEBEB",
						}}
						className="tw-rounded-xl tw-p-4 tw-w-full">
						<span className="tw-text-sm tw-font-normal satoshi tw-text-[#001302]">
							What brings you to me today?
						</span>
					</div>
				</div>
				<div className="tw-float-end tw-items-center tw-w-3/4 tw-flex tw-gap-4">
					<div
						style={{
							border: "0.5px solid #4ABE82",
						}}
						className="tw-rounded-full tw-p-4 tw-w-full">
						<span className="tw-text-sm tw-font-normal satoshi tw-text-[#001302]">
							What brings you to me today?
						</span>
					</div>
					<img src={Avatar} alt="" className="" />
				</div>
			</div>
		</div>
	);
};

const GroupsDetail = () => <MainGroupsDetail />;

export default GroupsDetail;
