import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/pageheader";
import PageFooter from "../../components/pagefooter";
import { Link, useLocation } from "react-router-dom";
import useCourseStore from "../../data/stores/coursestore";
import { GlobalState } from "../../data/Context";
import { ContentWriteup } from "../../pages/courses/[id]";

const CourseDetail = () => {
	return (
		<div>
			<PageHeader />
			<Details />

			<PageFooter />
		</div>
	);
};

const Details = () => {
	let [datum, setDatum] = useState(null),
		courses = useCourseStore(),
		{ state } = useLocation(),
		{ numberWithCommas } = useContext(GlobalState);

	useEffect(() => {
		if (courses?.data?.docs)
			courses?.data?.docs?.map(it => it?._id === state?._id && setDatum(it));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courses?.data, state]);

	if (!datum) return;

	return (
		<div>
			<div
				style={{
					backgroundImage: `url(${require("../../assets/bluebg.png")})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "100% 100%",
				}}
				className="tw-py-20">
				<div className="container tw-grid lg:tw-grid-cols-2 tw-gap-6 ">
					<div className="tw-flex tw-justify-center tw-items-center">
						<img
							src={
								datum?.image?.url ||
								datum?.module?.[0]?.image?.url ||
								require("../../assets/LeadershipImg.png")
							}
							className="tw-w-[40vh] lg:tw-w-full"
							alt={datum?.title}
						/>
					</div>
					<div className="tw-h-full tw-p-2 lg:tw-p-8">
						<p className="tw-text-4xl tw-font-bold tw-text-white mont tw-capitalize">
							{datum?.title}
						</p>
						<p className="tw-text-xl tw-font-thin tw-text-white tw-mt-8">
							{datum?.caption}
						</p>
						<p className="tw-text-xl tw-text-black lg:tw-text-white tw-mt-8">
							{" "}
							Learning Duration:{" "}
							<span className="tw-font-bold tw-text-2xl">
								{datum?.courseDuration} week(s)
							</span>
						</p>
						<p className="tw-text-xl tw-text-black lg:tw-text-white tw-mb-8 tw-mt-6">
							{" "}
							Starting Fee:{" "}
							<span className="tw-font-bold tw-text-2xl">
								NGN {numberWithCommas(Number(datum?.amount || 0).toFixed(2))}
							</span>
						</p>
						<Link
							className="tw-bg-[#0B2239] lg:tw-bg-white tw-text-xl tw-rounded-[70px] tw-text-white lg:tw-text-[#0B2239] tw-px-5 tw-py-3"
							to={"/register"}>
							Get Started
						</Link>
					</div>
				</div>
			</div>

			<div className="container">
				<p className="tw-mt-20 tw-text-4xl tw-font-bold tw-text-[#0B2239]">
					What You will Learn
				</p>
				{/* <p className="tw-mt-10 tw-text-lg tw-text-[#0B2239] tw-leading-loose">
					{datum?.description}
				</p> */}
				{ContentWriteup(
					state?.description,
					"tw-mt-10 tw-text-lg tw-text-[#0B2239] tw-leading-loose"
				)}

				<p className="tw-mt-20 tw-mb-20 tw-text-4xl tw-font-bold tw-text-[#0B2239]">
					Other Courses
				</p>
				<div className="tw-gap-3 tw-flex tw-justify-between tw-flex-wrap">
					{datum?.module?.map((ic, i) => (
						<div
							key={i}
							className=" tw-border-[1px] tw-border-[#0B2239] tw-py-7 tw-px-9 tw-rounded-2xl tw-mb-8">
							<p className="tw-text-2xl">{ic?.title}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default CourseDetail;
